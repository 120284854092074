import Vue from "vue";
import Vuex from "vuex";
import Iview from 'iview';
import 'iview/dist/styles/iview.css';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

//modules
import user from "./modules/user.js"

export default new Vuex.Store({
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    user,
  },
  plugins: [createPersistedState()]
});
