import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import moment from 'moment'


import {getToken} from './utils/util';

Vue.prototype.$moment = (date,format)=>{
  date = date || new Date(),
  format = format || "YYYY-MM-DD HH:mm";
  return moment(date).format(format)
};
Vue.prototype.$momentDate = (date,format)=>{
  date = date || new Date(),
  format = format || "YYYY-MM-DD";
  return moment(date).format(format)
};

Vue.prototype.$getToken = getToken;

Vue.config.productionTip = false
Vue.use(ViewUI);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
