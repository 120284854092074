import axios from "./api.request";
import { LoadingBar } from "iview";
import config from "../config";
import OSS from 'ali-oss';

export default {
  get(api = "", params = {}) {
    LoadingBar.start();
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: api,
          params,
          method: "get"
        })
        .then(function(response) {
          LoadingBar.finish();
          resolve(response.data);
        })
        .catch(function(error) {
          LoadingBar.finish();
          console.log(error);
          reject(error);
        });
    });
  },
  post(api = "", params = {}) {
    LoadingBar.start();
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: api,
          data: params,
          method: "post"
        })
        .then(function(response) {
          LoadingBar.finish();
          resolve(response.data);
        })
        .catch(function(error) {
          LoadingBar.finish();
          console.log(error);
          reject(error);
        });
    });
  },
  uploadOSS(src, file) {
    try {
    const OssClient = new OSS({
      region: config.oss.region,
      endpoint: config.oss.region,
      accessKeyId: config.oss.accessKeyId,
      accessKeySecret: config.oss.secretAccessKey,
      bucket: config.oss.bucket
    });
    return new Promise((resolve, reject) => {
      OssClient.put(`${src}/` + file.name, file).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      });
    })
  } catch (error) {
    console.log(error)
  }
  }
};
