// let protocol = location.protocol === 'https:' ? 'wss' : 'ws';
export default {
  baseUrl: {
    dev: "http://localhost:2000/",
    pro: "https://socket.aidbuys.com/"
  },
  ws: {
    dev: "ws://127.0.0.1:2001",
    pro: "wss://socket.aidbuys.com",
  },
  oss: {
    accessKeyId: 'LTAIlhVlFVVRh6b2',
    secretAccessKey: 'Mb8du0Q2xu9ASLIiryOwjow2v7cbu5',
    bucket: "aidprintfile",
    region: "oss-cn-chengdu.aliyuncs.com",
  }
};
