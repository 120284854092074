import { Login, UserInfo, SendImagesMessage, SendTextMessage } from "@/api/user";
import { ConnectSocket, SendMessage, SendSocketMessage } from "../../socket/index";

import { setToken, getToken, } from '@/utils/util'
import moment from 'moment';
export default {
  state: {
    user: {},
    socket: null,
    theme: [],
    chat: {},
    selectTheme: {}
  },
  getters: {
    user: state => state.user,
    theme: state => state.theme,
    chat: state => state.chat,
    selectTheme: state => state.selectTheme,
  },
  actions: {
    Login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Login(payload).then(res => {
          const result = res.data;
          if (res.status) {
            commit('SET_USER', result.user);
            commit('SET_TOKEN', result.token);
            ConnectSocket(result.token);
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    UserInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        UserInfo(payload).then(res => {
          const result = res.data;
          if (res.status) {
            commit('SET_USER', result.user);
            commit('SET_TOKEN', result.token);
            ConnectSocket(result.token);
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    SaveTheme({ commit }, payload) {
      commit('SET_THEME', payload);
    },
    SendMessage({ commit }, payload) {
      SendSocketMessage(payload);
    },
    ReadMessage({ commit }, payload) {
      let theme = this.state.user.theme;
      theme.map(item => {
        if (item.id === payload) {
          item.count = 0;
        }
      })
      console.log('themethemetheme',theme)
      commit('SET_THEME', theme);
      SendSocketMessage({ action: 205, value: { theme_id: payload } });
    },
    GetTheme({ commit }, payload) {
      SendSocketMessage({ action: 201 });
    },
    GetChat({ commit }, payload) {
      commit('SET_SELECT_THEME', payload);
      SendSocketMessage({ action: 203, value: { theme_id: payload.id } });
    },
    SendImagesMessage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        SendImagesMessage(payload).then(res => {

          if (res.status) {

            console.log('发送成功')
            let chat = this.state.user.chat;
            let theme = this.state.user.theme;
            theme.map(item => {
              if (item.id === res.data.theme_id) {
                item.update_time = new Date();
                item.showChatTime = moment(item.update_time).format('HH:mm');
                item.describe = '图片';
              }
            })
            commit('SET_THEME', theme);

            if (!chat[res.data.theme_id]) chat[res.data.theme_id] = [];
            chat[res.data.theme_id].push(res.data);
            console.log('chat', chat)
            commit('SET_CHAT', chat);
          }


          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    SendTextMessage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        SendTextMessage(payload).then(res => {
          if (res.status) {
            let chat = this.state.user.chat;
            let theme = this.state.user.theme;
            theme.map(item => {
              if (item.id === payload.theme_id) {
                item.update_time = new Date();
                item.showChatTime = moment(item.update_time).format('HH:mm');
                item.describe = payload.content;
              }
            })
            commit('SET_THEME', theme);

            if (!chat[payload.theme_id]) chat[payload.theme_id] = [];
            chat[payload.theme_id].push(res.data);
            commit('SET_CHAT', chat);
          }

          resolve(res)

        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
      setToken(payload);
    },
    SET_SOCKET(state, payload) {
      state.socket = payload;
      SendSocketMessage({ action: 201 })
    },
    SET_THEME(state, payload) {
      state.theme = payload;
    },
    SET_CHAT(state, payload) {
      state.chat = payload;
      localStorage.chat = JSON.stringify(payload)
    },
    SET_SELECT_THEME(state, payload) {
      sessionStorage.selectTheme = JSON.stringify(payload);
      state.selectTheme = payload;

    }
  }
}
