import net from "../utils/net";
export const Login = data => {
  return net.post("app/user/login", data);
};
export const UserInfo = data => {
  return net.get("app/user/info", data);
};
export const SendImagesMessage = data => {
  return net.post("app/wechat/SendImagesMessage", data);
};
export const SendTextMessage = data => {
  return net.post("app/wechat/SendTextMessage", data);
};