<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" >
#app{
  width: 100%;
  height: 100%;
}
body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
