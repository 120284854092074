import store from '../store/index';
import config from '../config/index';
import moment from 'moment'

let socket = null, timer = null;

export const ConnectSocket = (token = null) => {
	if (!token) token = localStorage.token;

	let WsUrl = `${process.env.NODE_ENV === "development" ? config.ws.dev : config.ws.pro}/websocket?token=${token}`;
	socket = new WebSocket(WsUrl);

	socket.onopen = (e) => {
		store.commit('SET_SOCKET', socket);
	}
	socket.onclose = () => {
		setTimeout(() => {
			ConnectSocket();
		}, 5000)
	}
	socket.onerror = e => {
		console.error(e);


	}
	socket.onmessage = e => {
		let data = null;
		if (e.data.length > 3) data = JSON.parse(e.data);
		else return false;
		if (!data.action || data.action === 100) return false;
		console.log('接收消息', data)
		switch (data.action) {
			case 201:
				GetTheme(data);
				break;
			case 203:
				GetChat(data);
				break;
			case 204:
				ReceiveMessage(data);
				break;
			default:
		}
	}
	if (!timer) {
		timer = setInterval(() => {
			SendSocketMessage({ action: 100 });
		}, 10000);
	}
}


export const SendMessage = (MsgData) => {
	let data = {
		action: 203,
		Value: {
			ThemeId: MsgData.ThemeId,
			Type: MsgData.Type || 0,
			Content: MsgData.Content
		}
	};
	SendSocketMessage(data);
}

export const GetTheme = data => {
	const format = "YYYY-MM-DD", format1 = "HH:mm", selfTime = moment(new Date()).format(format);
	const selectTheme = sessionStorage.selectTheme ? JSON.parse(sessionStorage.selectTheme) : null, chat = store.getters.chat;

	let count = {};
	for (let key in chat) {
		chat[key].map(item => {
			if (!count[item.theme_id]) count[item.theme_id] = 0;
			if (item.status === 0) count[item.theme_id]++;
		})
	}

	console.log('countcount', count)
	data.value.map((item, index) => {
		item.count = count[item.id] ? count[item.id] : 0;
		if (selectTheme) {
			if (item.id == selectTheme.id) item.active = true;
		} else {
			if (!sessionStorage.selectThemeId && index === 0) item.active = true;
			else item.active = false;
		}
		const chatTime = moment(item.update_time).format(format);
		if (chatTime < selfTime) {
			item.showChatTime = moment(item.update_time).format('MM-DD');
		} else {
			item.showChatTime = moment(item.update_time).format(format1);
		}
	})
	store.commit('SET_THEME', data.value);
}


export const ReceiveMessage = data => {
	let chat = store.getters.chat, theme = store.getters.theme, selectTheme = store.getters.selectTheme;
	if (!chat[data.value.theme_id]) chat[data.value.theme_id] = [];
	chat[data.value.theme_id].push(data.value);

	let count = 0;
	chat[data.value.theme_id].map(item => {
		if (item.status === 0) count++;
	})
	store.commit('SET_CHAT', chat);
	theme.map(item => {
		if (item.id === data.value.theme_id) {
			item.update_time = new Date();
			item.showChatTime = moment(item.update_time).format('HH:mm');
			item.describe = data.value.msg_type === 'image' ? '图片' : data.value.content;
			item.count = count;
			// if (selectTheme.id !== item.id) item.count = count;
			// else item.count = 0;
		}
	})
	store.commit('SET_THEME', theme);
	setTimeout(() => {
		setScrollHeight();
	}, 100);
}


export const GetChat = data => {
	let chat = store.getters.chat, theme = store.getters.theme;

	let count = {};
	for (let key in data.value.chat) {
		chat[key] = data.value.chat[key];
		data.value.chat[key].map(item => {
			if (!count[item.theme_id]) count[item.theme_id] = 0;
			if (item.status === 0) count[item.theme_id]++;
		})
	}
	store.commit('SET_CHAT', chat);

	theme.map(item => {
		if (count[item.id]) item.count = count[item.id];
	})
	store.commit('SET_THEME', theme);



}


export const SendSocketMessage = data => {
	if (typeof data === 'object') data = JSON.stringify(data);
	socket.send(data);
}


const setScrollHeight = () => {
	const showContent = document.getElementById("person2");
	showContent.scrollTop = showContent.scrollHeight;
};
